import React, { useState } from 'react'
import loginService from '../services/login'
import Popup from 'reactjs-popup'

/**
   Formular für einen einmaligen Zugang ohne Password
*/

function OneTimeLogin() {

  function handleChange(evt) {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value
    })
  }


  const [state, setState] = useState({
    email: '',
  })



  //post damit ein einmalig gültiger Zugang generiert wird
  const postOneTimeLogin = async () => {
    const requestBody = state
    try {
      await loginService.postOneTimeLogin(requestBody)
    } catch (exception) {
      console.log('postOneTimeLogin', { exception })
    }
  }

  return (
    <div>
      <div className="form-style-2">
        <div className="form-style-1-heading"><h3>Beantragen eines einmaligen Zugangscode</h3></div>
        <form className='form'>
          <label className="field2">
            <div className="header"> <span> Ihre PFH-E-Mail Adresse: <span className="required">*</span></span></div>
            <input type="email" className="input-field" name="email" value={state.email} onChange={handleChange} />
          </label>
          <Popup
            trigger={<label><span> <input className="button" type="button" value="Absenden" onClick={() => postOneTimeLogin()} /></span></label>}
            modal
            nested
          >
            {close => (
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <div className="header"> Antrag für einen einmaligen Mailzugang wurde versendet. </div>
                <div className="content">
                  {' '}
                  Prüfen Sie ihren Mailaccount.
                </div>
                <div className="actions">

                  <button
                    className="button"
                    onClick={() => close()}
                  >
                    Schließen
                  </button>
                </div>
              </div>
            )}
          </Popup>
        </form>

      </div>


    </div>
  )
}

export default OneTimeLogin
