import React, { useEffect, useState } from 'react'
import { Link, useParams, withRouter, useHistory } from 'react-router-dom'
import studienService from '../services/studien'
import './StudienDetails.css'
import './StudienList.css'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import StudienDetails from './StudienDetails'



/**
  - Nur User, die Versuchsleiter oder Betreuer einer Studie sind, können diesen Bereich sehen.
  - Hier kann man die Liste aller VPS IDs sehen, die an der eigens erstellten Studie teilnehmen, sowie die Details der Studie.
  - Außerdem gibt es die Möglichkeit die Teilnahme der VPS iDs (Sammler) zu bestätigen, sodass ihnen die VPStunden gutgeschrieben werden können.

   @component

*/
function MeineStudienDetails(props) {
  function handleChange(evt) {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value
    })
  }

  const [state, setState] = useState({
    genehmigteSammler: '',

  })
  const [message, setMessage] = useState('')
  const studienID = useParams().id
  const [studie, setStudie] = useState(props.studie || props.location.state)
  useEffect(() => {
    const fetchData = async () => {
      const detail = await studienService.getDetail(studienID)
      setStudie(detail)
    }
    if (studie === undefined) { fetchData() }
  }, [])
  const [success, setSuccess] = useState(false)
  const history = useHistory() // TODO If switching to react-router-dom 6, change to useNavigate

  const addTeilnahmebestaetigung = async () => {
    if(studie.studieangenommen){
      const result = await studienService.sammlerBestaetigen(studie._id, state.genehmigteSammler.split(/\s/))
      setState({ genehmigteSammler: result.toString().replace(/,/g, '\n') })
      if (result.length === 0) {
        setMessage('IDs wurden erfolgreich bestätigt.')
        setSuccess(true)
      }
      else {
        setMessage('Es wurden eine oder mehrere falsche IDs eingegeben. Bitte korrigieren Sie die Eingabe.')
        setSuccess(false)
      }
      console.log('Message', { message })
    }
    else{
      alert('Studie ist noch nicht angenommen')
    }
  }

  const deleteStudie = () => {
    studienService.deleteStudie(studie._id)
    history.push('/')
  }

  //Formatiert das Datum in das deutsche Format tt/MM/JJJJ
  //const formatter = new Intl.DateTimeFormat('de', { year: 'numeric', month: '2-digit', day: 'numeric' })

  //Error Handler falls die Details der Studie nicht (schnell genug) laden.
  if (studie === undefined) {
    return (
      <div>
        <div> <h1>Meine Studien</h1> </div>
        <div> Ein Fehler liegt vor.</div>
      </div>
    )
  }

  return (
    <div>
      <div className="wrapper">
        <StudienDetails studie={studie} />
        <div className="lower-part">
          <a href={`/aktualisieren/${studienID}`} >
            <button className="button" type="button">Ändern</button>
          </a>
          { // Nur Betreuer können eine Studie löschen
            !props.user.betreuer ? <span></span> :
              <Popup
                trigger={<input className="button" type="button" value="Löschen" />}
                modal
                nested
              >
                {close => (
                  <div className="modal">
                    <div className="header"> Studie Löschen?</div>
                    <div className="content">
                      {' '}
                      Sind Sie sicher? Dieser Vorgang kann nicht rückgängig gemacht werden.
                    </div>
                    <div className="actions">
                      <button className="close" onClick={() => { deleteStudie(); close() }}>
                        <Link className="link-close" to="/meine-studien">Ok</Link>
                      </button>
                      <button
                        className="button"
                        onClick={() => { close() }}
                      >
                        Abbrechen
                      </button>
                    </div>
                  </div>
                )}
              </Popup>
          }
        </div>
      </div>
      <div className="header">Teilnahme:</div>
      <div className="grid-item">Bitte tragen Sie hier die Versuchspersonen IDs der Teilnehmer ein, um deren Teilnahme zu bestätigen. <br /> Nutzen Sie zum Trennen der IDs bitte ein Leerzeichen.</div>
      <div className={`grid-item ${success ? 'success' : 'failure'}`}> {message}</div>

      <textarea cols="40" rows="10" type="text" className="textarea-field" name="genehmigteSammler"
        value={state.genehmigteSammler} onChange={handleChange} />
      <div className="ButtonNewLine"></div>
      {<label><span> <input className="button" type="button"
        value="Teilnahme bestätigen" onClick={addTeilnahmebestaetigung}>
      </input>
      </span></label>}
    </div>
  )
}
MeineStudienDetails.propTypes = {
  studie: PropTypes.shape({
    studienleitername: PropTypes.string,
    studienleiteremail: PropTypes.string,
    studienstunden: PropTypes.number,
    startdatum: PropTypes.string,
    enddatum: PropTypes.string,
    kriterien: PropTypes.string,
    details: PropTypes.string,
  }),
  user: PropTypes.shape({
    betreuer: PropTypes.bool,
  })
}
export default withRouter(MeineStudienDetails)
