import axios from 'axios'
const inProduction = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
const serverHost = inProduction ? '' : 'http://localhost:3001'

const postOneTimeLogin = async (email) => {
  const url = serverHost + '/api/login/OneTimeLogin'
  await axios.post(url, email)
}

const backendVersion = async () => {
  const url = serverHost + '/api/info/backendVersion'
  const result = await axios.get(url)
  return result.data.backendVersion
}

export default { postOneTimeLogin, backendVersion }