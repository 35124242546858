import React, { useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import studienService from '../services/studien'
import userService from '../services/user'
import './StudieForm.css'

/**
   Formular zum Erstellen oder Aktualisieren einer Studie. Wenn mit param id aufgerufen (e.g. /aktualisieren/1234)
   dann wird die Studie geladen. Sonst wird eine neue Studie erstellt. Diese Fälle sind möglich:
   1. Student erstellt eine Studie. Der Student wird als Studienleiter gesetzt. Der Betreuer wird benachrichtigt.
   2. Student aktualisiert eine Studie. Der Student muss der Studienleiter sein. Der Betreuer wird benachrichtigt.
   3. Professor erstellt oder ändert eine Studie mit sich als Betreuer. Die Änderung erfolgt ohne Benachrichtigung.
   4. Professor erstellt oder ändert eine Studie mit einem anderen Betreuer. Der neue Betreuer wird benachrichtigt.

   @component
   @example
   return (<StudieForm  user={{betreuer: true, email: 'professor1@pfh.de'}}/>
*/

const StudieForm = (props) => {
  const studienID = useParams().id
  const [inputs, setInputs] = useState({
    betreuer: props.user.betreuer ? props.user.email : '',
    studienname: '',
    studienleiteremail: props.user.betreuer ? '' : props.user.email,
    studienstunden: '1',
    startdatum: new Date().toISOString().substring(0, 10),
    enddatum: new Date().toISOString().substring(0, 10),
    kriterien: '',
    details: '',
    zustimmung: false,
  })
  const [messages, setMessages] = useState({
    betreuer: '',
    studienname: '',
    studienleiteremail: '',
    studienstunden: '',
    startdatum: '',
    enddatum: '',
    kriterien: '',
    details: '',
    zustimmung: '',
    absenden: ''
  })
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setInputs(await studienService.getDetail(studienID))
    }
    if (studienID !== undefined && inputs.studienname === '') { fetchData()}
  }, [])

  const handleButtonOnClick = async () => {
    const requiredFields = ['betreuer', 'studienname', 'studienleiteremail',
      'studienstunden', 'startdatum', 'enddatum', 'kriterien', 'details']
    const newMessages = {}
    let valid = true
    for (const name of requiredFields) {
      if (inputs[name] === '') {
        newMessages[name] = 'Pflichtfeld'
        valid = false
      }
    }
    const startdatum = new Date(inputs.startdatum) // at time 00:00.000Z
    const enddatum = new Date(inputs.enddatum)
    if (startdatum >= enddatum) { // always false for invalid dates
      newMessages.enddatum = 'Enddatum muß nach dem Startdatum sein'
      valid = false
    }
    if (isNaN(startdatum)) {
      newMessages.startDatum = 'Ungültiges Datum'
      valid = false
    }
    if (isNaN(enddatum)) {
      newMessages.endDatum = 'Ungültiges Datum'
      valid = false
    }
    if (isNaN(inputs.studienstunden)) {
      newMessages.studienstunden = 'Ungültige Zahl'
      valid = false
    }
    if (!props.user.betreuer && props.user.email !== inputs.studienleiteremail) {
      newMessages.studienleiteremail = 'Sie sind nicht der Studienleiter'
      valid = false
    }
    if (!props.user.betreuer && studienID === undefined && !inputs.zustimmung) {
      newMessages.zustimmung = 'Pflichtfeld'
      valid = false
    }
    try {
      const betreuerUser = await userService.getUserbyMail(inputs.betreuer)
      if (betreuerUser === undefined || !betreuerUser.betreuer) {
        newMessages.betreuer = 'Kein Betreuer mit dieser Adresse im System'
        valid = false
      }
      if ((await userService.getUserbyMail(inputs.studienleiteremail)) === undefined) {
        newMessages.studienleiteremail = 'Kein Nutzer mit dieser Adresse im System'
        valid = false
      }
      if (valid) {
        // Dates must be strings because JSON can't handle Date objects
        const request = { ...inputs, startdatum: startdatum.toISOString(), enddatum: enddatum.toISOString() }
        request.studienstunden = Number.parseFloat(request.studienstunden)
        delete request.zustimmung
        if (studienID === undefined) {
          await studienService.studieEinreichen(request)
          newMessages.absenden = props.user.betreuer
            ? 'Die Studie wurde freigegeben.'
            : 'Die Studie wurde an Ihren Betreuer gesendet und wird nach Prüfung freigegeben.'
        } else {
          await studienService.studieAktualisieren(request)
          newMessages.absenden = props.user.betreuer
            ? 'Die Studie wurde aktualisiert'
            : 'Die aktualisierte Studie wurde an Ihren Betreuer gesendet und wird nach Prüfung freigegeben.'
        }
        setDisableSubmitButton(true)
      } else {
        newMessages.absenden = 'Interner Webseiten-Fehler'
      }
    }
    catch (exception) {
      newMessages.absenden = 'Interner Server-Fehler'
      console.log('Studieform', exception)
      valid = false
    }

    setMessages({ ...messages, ...newMessages })
  }

  function handleChange(evt) {
    const value = evt.target.value
    setInputs({
      ...inputs,
      [evt.target.name]: value,
    })
    setMessages({
      ...messages,
      [evt.target.name]: ''
    })
  }

  return (
    <div className='studieform'>
      <h3>{studienID === undefined ? 'Studie einreichen' : 'Studiendetails aktualisieren'}</h3>
      <form>
        <label>
          <span>Titel</span>
          <input type="text" name="studienname" value={inputs.studienname} size="40" onChange={handleChange}/>
        </label>
        <span className="message">{messages.studienname}</span>
        <label>
          <span>Studienleiter E-Mail</span>
          <input type="text" name="studienleiteremail" value={inputs.studienleiteremail} size="25" disabled={!props.user.betreuer} onChange={handleChange}/>
        </label>
        <span className="message">{messages.studienleiteremail}</span>
        <label>
          <span>Betreuer E-Mail</span>
          <input type="text" name="betreuer" value={inputs.betreuer} size="25" onChange={handleChange}/>
        </label>
        <span className="message">{messages.betreuer}</span>
        <label>
          <span>Startdatum</span>
          <input type="date" name="startdatum" value={inputs.startdatum} onChange={handleChange}/>
        </label>
        <span className="message">{messages.startdatum}</span>
        <label>
          <span>Enddatum</span>
          <input type="date" name="enddatum" value={inputs.enddatum} onChange={handleChange}/>
        </label>
        <span className="message">{messages.enddatum}</span>
        <label>
          <span>Stunden</span>
          <input type="number" min="0" max="1000" step="any" name="studienstunden" value={inputs.studienstunden} size="4" onChange={handleChange}/>
        </label>
        <span className="message">{messages.studienstunden}</span>
        <label>
          <span>Kriterien</span>
          <textarea type="text" name="kriterien" value={inputs.kriterien} rows="10" onChange={handleChange}></textarea>
        </label>
        <span className="message">{messages.kriterien}</span>
        <label>
          <span>Details</span>
          <textarea type="text" name="details" value={inputs.details} rows="10" onChange={handleChange}></textarea>
        </label>
        <span className="message">{messages.details}</span>
        { props.user.betreuer || studienID !== undefined ? null :
          <label>
            <input type="checkBox" className="checkBoxInput" name="zustimmung" onChange={handleChange}></input>
            <span>
              Ich verpflichte mich, die Versuchspersonen IDs der Teilnehmer an dieser Studie innerhalb von 7 Tagen nach dem angegebenen Enddatum in die Versuchspersonendatenbank einzutragen.
            </span>
            <span className="message">{messages.zustimmung}</span>
          </label>
        }
        <span></span><span><input className="button" type="button" value={studienID === undefined ? 'Absenden' : 'Aktualisieren'} disabled={disableSubmitButton} onClick={() => { handleButtonOnClick() }} />
          <div>{messages.absenden}</div></span>
      </form>
    </div>
  )
}

StudieForm.propTypes = {
  user: PropTypes.shape({
    betreuer: PropTypes.bool,
    email: PropTypes.string
  })
}

export default withRouter(StudieForm)