import React, { useState, useEffect } from 'react'
import logo from '../logo.jpg'
import './Header.css'
import { Link } from 'react-router-dom'
import environment from '../environment'
import login from '../services/login.js'

/**
   - Alle können diesen Bereich sehen
   - Gibt das Menüband aus
   - Anträge sollen nur vom Betreuer sichtbar sein

   @component

*/

function Header() {
  //Alle können diesen Bereich sehen
  //Anträge sollen nur vom Betreuer sichtbar sein, Betreuer muss meine Stunden nicht sehen, da er an keinen Studien teilnimmt.
  //Gibt das Menüband aus

  const [ backendVersion, setBackendVersion ] = useState('')

  useEffect(() => {
    const getGitVersionFromBackend = async () => {
      const result = await login.backendVersion()
      setBackendVersion(result)
    }
    getGitVersionFromBackend()
  }, [])

  return (

    <div>
      <div className="banner">

        <Link className="h_txt" to="/ausgeschriebene-studien">Ausgeschriebene Studien | </Link>
        <Link className="h_txt" to="/studie-einreichen">Studie einreichen | </Link>
        {/* { betreuer ?  <div/> : <Link className="h_txt" to="/meine-stunden">Meine Stunden | </Link> } */}
        <Link className="h_txt" to="/meine-stunden">Meine Stunden | </Link>
        <Link className="h_txt" to="/nutzer">Mein Profil |</Link>
        <Link className="h_txt" to="/antraege">Anträge |</Link>
        {/* { betreuer ?  <Link className="h_txt" to="/antraege">Anträge | </Link> : <div/>} */}
        <Link className="h_txt" to="/meine-studien">Meine Studien |</Link>
        {process.env.NODE_ENV === 'development' ? <Link className="h_txt" to="/doc">Documentation</Link> : <span></span>}
        <img className="logo_img" src={logo} alt="Logo" />
        <p style = {{ fontSize: '50%', fontFamily: 'Open Sans', padding: '5px', color: '#ffffff', textDecoration : 'none' }}>
          Git Version Frontend: {environment.VERSION_FRONTEND}
          <br/>
          Git Version Backend: {backendVersion}
        </p>
      </div>
    </div>
  )
}



export default Header
