/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react'
import './MeineStunden.css'
import studienService from '../services/studien'
import Popup from 'reactjs-popup'
import StudienList from './StudienList'

const MeineStunden = ({ user }) => {
  const userName = user.email

  const [meineStudien, setMeineStudien] = useState([])
  const [meineStunden, setMeineStunden] = useState(0)
  const [location, setLocation] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      if (userName === undefined) return
      // TODO: Make single call
      const studien = await studienService.getSammlerTeilgenommeneStudien(userName)
      setMeineStudien(studien)
      const stunden = await studienService.getSammlerStunden(userName)
      setMeineStunden(stunden)
    }
    fetchData()
  }, [])

  const sendSammlerStundenbericht = async () => {
    if (location === 'campus') {
      await studienService.sendSammlerStundenbericht(user.email, location === 'campus')
    }
    else {
      await studienService.sendSammlerStundenbericht(user.email, location === 'fern')
    }
  }

  return (
    <div>
      <h1>Meine Stunden</h1>
      <h2>Stunden: {meineStunden} </h2>
      <div>
        <fieldset>
          <legend>Ich bin: </legend>

          <div>
            <input type="radio" name="location" id="fern" value="fern" checked={location === 'fern'} onChange={ event => setLocation(event.target.value)} />
            <label htmlFor="fern">Fernstudent/in</label>
          </div>

          <div>
            <input type="radio" name="location" id="campus" value="campus" checked={location === 'campus'} onChange={ event => setLocation(event.target.value)} />
            <label htmlFor="campus">Campusstudent/in</label>
          </div>
        </fieldset>

        <Popup
          trigger={<label><span> <input disabled={!(location==='campus'|| (location==='fern' && meineStunden>=30))} className="btn_bestaetigen" type="button" value="Bestätigung an das Prüfungsamt" onClick={() => sendSammlerStundenbericht()} /></span></label>}
          modal
          nested
        >
          {close => location === '' ?
            close() :
            <div className="modal">
              <button className="close" onClick={close}>
                &times;
              </button>
              <div className="header"> Stunden wurden verschickt </div>
              <div className="content">
                {' '}
                Ihre Stunden wurden an das Prüfunsamt geschickt und werden überprüft.
                <br />

              </div>
              <div className="actions">

                <button
                  className="button"
                  onClick={close}
                >
                  Schließen
                </button>
              </div>
            </div>
          }
        </Popup>
      </div>
      <StudienList studien={meineStudien} key={`meine-stunden/${userName}`} detailPath="meine-stunden" />
    </div>
  )
}

export default MeineStunden
