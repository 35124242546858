/**
 * REST Requests zum Erhalten von Benutzerdaten
 * @module services/user
 */

import axios from 'axios'
const inProduction = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
const serverHost = inProduction ? '' : 'http://localhost:3001'

const getNeueVpsid = async (vpsid) => {
  const getNeueVpsidURL = serverHost + '/api/users/generieren'
  return await axios.post(getNeueVpsidURL, vpsid)
}

const getUser = async () => {
  const user = await axios.get(serverHost + '/loggedIn')
  return user.data
}

const getUserbyMail = async (email) => {
  if (email === undefined || email.trim() === '') return undefined
  const user = await axios.get(serverHost + '/api/users/' + email)
  if(!user.data){
    return undefined
  }
  else{
    return user.data
  }
}

export default {
  getNeueVpsid,
  getUser,
  getUserbyMail
}

