import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import Nutzer from './components/Nutzer'
import StudieForm from './components/StudieForm'
import MeineStunden from './components/MeineStunden'
import Header from './components/Header'
import StudienList from './components/StudienList'
import AntragDetails from './components/AntragDetails'
import StudienDetails from './components/StudienDetails'
import MeineStudienDetails from './components/MeineStudienDetails'
import OneTimeLogin from './components/OneTimeLogin'
import Doc from './components/Doc'
import axios from 'axios'
import studienService from './services/studien'

// https://stackoverflow.com/questions/43002444/make-axios-send-cookies-in-its-requests-automatically
axios.defaults.withCredentials = true

// https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(/*error*/) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('ErrorBoundary', error, errorInfo)
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>
    }
    return this.props.children
  }
}

const App = () => {
  const [user, setUser] = useState({})
  const inProduction = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
  const serverHost = inProduction ? '' : 'http://localhost:3001'

  useEffect(() => {
    const getLoggedUser = async () => {
      try {
        if (window.location.href.endsWith('/loginSucceeded')) {
          const user = (await axios.get(serverHost + '/loggedIn')).data
          setUser(user)
          window.sessionStorage.setItem('loggedUser', JSON.stringify(user))
        }
        else if (window.location.href.endsWith('/logoutSucceeded')) {
          setUser({})
          window.sessionStorage.removeItem('loggedUser')
        }
        else {
          const loggedUser = window.sessionStorage.getItem('loggedUser')
          if (loggedUser) {
            const user = JSON.parse(loggedUser)
            setUser(user)
          }
        }
      }
      catch (e) {
        console.error('App.js useEffect', e)
      }
    }
    getLoggedUser()
  }, [])


  /*
    TODO: Für den Login-Button muss es einen besseren Weg geben...
    Jetzt macht er einen Redirect nach /login (nach 3001:/login wenn nicht
      in Produktion)
    Dann macht SSO sein Redirect zum Provider, redirect zum
    callback, wo der session state gesetzt wird, und noch einen Redirect nach /
    Dann wird vom effect hook die login info from session state gelesen
    Das sollte eigentlich ohne einen page flip gehen
    https://stackoverflow.com/questions/50644976/react-button-onclick-redirect-page
  */
  const LoginInfo = () => {
    if ('_id' in user) {
      return (
        <div>
          {/*setUser = undefined funktioniert nicht, danach funktioniert der ganze Logout nicht mehr -> && setUser() === undefined */}
          {<button onClick={() => { window.location.href = serverHost + '/logout' }}>Abmelden</button>}
          <b>{user.name}</b> eingeloggt mit Rolle <b>{user.betreuer ? 'Professor' : 'Student'}</b>
        </div>
      )
    }
    return (
      <div>
        <button onClick={() => { window.location.href = serverHost + '/login' }}>Anmelden</button>
        <button onClick={() => { window.location.href = '/einmaliger-zugang' }}>Zugang per Email Link</button>
        <p>Bitte melden Sie sich mit Ihren Zugangsdaten für myPFH an. Sollte dies zu einer Fehlermeldung führen, fordern Sie bitte ein Link zur Anmeldung an.</p>
      </div>
    )
  }

  console.log('App.js', { user })
  return (
    <div>
      <Header betreuer={user.betreuer} />
      <h1>Versuchspersonenstunden-Datenbank</h1>
      {window.location.href.endsWith('/doc') ? <div></div> : <LoginInfo />}
      <Switch>
        <Route path="/doc"><div><Doc /></div></Route>
        <Route path="/nutzer"> {
          '_id' in user ? <Nutzer user={user} /> :
            <div>
              <div> Melden Sie sich an, um Ihr Profil einzusehen. </div>
            </div>
        }
        </Route>
        <Route path="/ausgeschriebene-studien/:id">
          <ErrorBoundary><StudienDetails /></ErrorBoundary>
        </Route>
        {/* studie={studie} */}
        <Route path="/ausgeschriebene-studien">
          <ErrorBoundary>{'_id' in user ?
            <StudienList
              title="Ausgeschriebene Studien"
              fetcher={studienService.verfuegbar}
              detailPath="ausgeschriebene-studien"
              key="ausgeschriebene-studien"
            /> :
            <div>
              <div> Melden Sie sich an, um die ausgeschriebenen Studien einzusehen. </div>
            </div>
          }
          </ErrorBoundary>
        </Route>
        <Route path="/studie-einreichen"> {
          '_id' in user ? <StudieForm user={user} /> :
            <div>
              <div> Melden Sie sich an, um eine Studie einzureichen. </div>
            </div>
        }
        </Route>
        <Route path="/meine-stunden/:id"> {
          <StudienDetails id={user._id} />
        }
        </Route>
        <Route path="/meine-stunden"> {
          '_id' in user ? <ErrorBoundary><MeineStunden user={user} /></ErrorBoundary> :
            <div>
              <div> Melden Sie sich an, um Ihre Stunden einzusehen. </div>
            </div>
        }
        </Route>
        <Route path="/antraege/:id"> {
          <AntragDetails user={user} />
        }
        </Route>
        <Route path="/antraege"> {
          '_id' in user ?
            <StudienList
              title="Anträge"
              fetcher={async () => user.betreuer ? studienService.getBetreuerAntraege(user.email) : studienService.getStudienleiterAntraege(user.email) }
              detailPath="antraege"
              key="antraege"
            /> :
            <div>
              <div> Melden Sie sich an, um einen Ihrer Anträge einzusehen. </div>
            </div>
        }
        </Route>
        <Route path="/meine-studien/:id">
          <MeineStudienDetails user={user} />
        </Route>
        <Route path="/aktualisieren/:id">
          <StudieForm user={user} />
        </Route>
        <Route path="/meine-studien"> {
          '_id' in user ?
            (<StudienList
              title="Meine Studien"
              fetcher={async () => user.betreuer
                ? studienService.getBetreuerStudien(user.email)
                : studienService.getStudienleiterStudien(user.email) }
              detailPath="meine-studien"
              key="meine-studien"
            />) :
            (<div>
              <div> Melden Sie sich an, um Ihre Studien einzusehen. </div>
            </div>)
        }
        </Route>
        <Route path="/loginSuccess"> {
          window.location.href.startsWith('http://localhost:3001/') ?
            window.location.href = window.location.href.replace('3001', '3000') : undefined
        }
        </Route>
        <Route path="/loginFailure"> {
          window.location.href.startsWith('http://localhost:3001/') ?
            window.location.href = window.location.href.replace('3001', '3000') : undefined
        }
        </Route>
        <Route path="/logoutSuccess"> {
          window.location.href.startsWith('http://localhost:3001/') ?
            window.location.href = window.location.href.replace('3001', '3000') : undefined
        }
        </Route>
        {<Route path="/einmaliger-zugang">
          {<OneTimeLogin />
          }
        </Route>}
      </Switch>
    </div>
  )
}
export default App
