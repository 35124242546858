import React from 'react'
import Nutzer from './Nutzer'
import StudienDetails from './StudienDetails'
import StudienList from './StudienList'
import StudieForm from './StudieForm'

const Doc = () => <div>
  <h1>JSDoc</h1>
  <ul>
    <li><a href="http://localhost:3000/docs">Client</a></li>
    <li><a href="http://localhost:3001/docs">Server</a></li>
  </ul>
  <h1>Komponenten</h1>
  <div className='Komponente'>
    <h2 id="Nutzer">Nutzer</h2>
    <pre>{'<Nutzer user={{ name: \'Fred Flintstone\', email: \'fred.flintstone@pfh.de\', vpsid: \'fröhlicher-hamster1773\' }}/>'}</pre>
    <Nutzer user={{ name: 'Fred Flintstone', email: 'fred.flintstone@pfh.de', vpsid: 'fröhlicher-hamster1773' }} />
  </div>
  <div className='Komponente'>
    <h2>StudienDetails</h2>
    <pre>{`<StudienDetails studie={{
        studienname: 'Studie XY',
        startdatum: new Date('2022-01-01'),
        enddatum: new Date('2021-12-31'),
        studienstunden: 2.5,
        kriterien: 'Zu erfüllendes Kriteruim 1, zu erfüllendes Kriterium 2, ...',
        details: 'Details der Studie'
        }}/>`}</pre>
    <StudienDetails studie={{
      studienname: 'Studie XY',
      startdatum: new Date('2022-01-01'),
      enddatum: new Date('2021-12-31'),
      studienstunden: 2.5,
      kriterien: 'Zu erfüllendes Kriteruim 1, zu erfüllendes Kriterium 2, ...',
      details: 'Details der Studie'
    }}/>
  </div>
  <div className='Komponente'>
    <h2>StudienList</h2>
    <pre>{`<StudienList
      title="Meine Studien"
      detailPath="ausgeschriebene-studien"
      studien={[{
        _id: '62371f903da58317e033b15c',
        studienname: 'Teststudie',
        studienleiteremail: 'student1@pfh.de',
        betreuer: 'professor1@pfh.de',
        startdatum: '2022-03-21T00:00:00.000Z',
        enddatum: '2022-04-30T00:00:00.000Z',
        studieoffen: true,
        studieangenommen: false,
        studienstunden: 10,
        kriterien: 'Teilnehmer müssen keine besonderen Kriterien erfüllen',
        details: 'Studie zum Testen 123',
        studienleitername: 'PFH Student 1',
        betreuername: 'PFH Professor 1',
        __v: 0
      },
      {
        _id: '623727edb5951705a4667451',
        studienname: 'Test E-Mail-ID',
        studienleiteremail: 'student1@pfh.de',
        betreuer: 'professor1@pfh.de',
        startdatum: '2022-03-20T00:00:00.000Z',
        enddatum: '2022-03-27T00:00:00.000Z',
        studieoffen: true,
        studieangenommen: false,
        studienstunden: 5,
        kriterien: 'keine',
        details: 'Test, um zu sehen, ob in der E-Mail an den Betreuer die ID korrekt angezeigt wird',
        studienleitername: 'PFH Student 1',
        betreuername: 'PFH Professor 1',
        __v: 0
      }]}/>`}</pre>
    <StudienList
      title="Meine Studien"
      detailPath="ausgeschriebene-studien"
      studien={[{
        _id: '62371f903da58317e033b15c',
        studienname: 'Teststudie',
        studienleiteremail: 'student1@pfh.de',
        betreuer: 'professor1@pfh.de',
        startdatum: '2022-03-21T00:00:00.000Z',
        enddatum: '2022-04-30T00:00:00.000Z',
        studieoffen: true,
        studieangenommen: false,
        studienstunden: 10,
        kriterien: 'Teilnehmer müssen keine besonderen Kriterien erfüllen',
        details: 'Studie zum Testen 123',
        studienleitername: 'PFH Student 1',
        betreuername: 'PFH Professor 1',
        __v: 0
      },
      {
        _id: '623727edb5951705a4667451',
        studienname: 'Test E-Mail-ID',
        studienleiteremail: 'student1@pfh.de',
        betreuer: 'professor1@pfh.de',
        startdatum: '2022-03-20T00:00:00.000Z',
        enddatum: '2022-03-27T00:00:00.000Z',
        studieoffen: true,
        studieangenommen: false,
        studienstunden: 5,
        kriterien: 'keine',
        details: 'Test, um zu sehen, ob in der E-Mail an den Betreuer die ID korrekt angezeigt wird',
        studienleitername: 'PFH Student 1',
        betreuername: 'PFH Professor 1',
        __v: 0
      }]}/>
  </div>
  <div className='Komponente'>
    <h2 id="Nutzer">StudieForm (Student)</h2>
    <pre>{`<StudieForm user={{    
      email: 'fred.flintstone@pfh.de', 
      betreuer: false 
    }}/>`}</pre>
    <StudieForm user={{
      email: 'fred.flintstone@pfh.de',
      betreuer: false
    }} />
  </div>
  <div className='Komponente'>
    <h2 id="Nutzer">StudieForm (Professor)</h2>
    <pre>{`<StudieForm user={{    
      email: 'wilma.flintstone@pfh.de', 
      betreuer: true 
    }}/>`}</pre>
    <StudieForm user={{
      email: 'wilma.flintstone@pfh.de',
      betreuer: true
    }} />
  </div>
</div>
// TODO AntragDetails, MeineStudienDetails, beide für Student und Professor

export default Doc