import React, { useState, useEffect } from 'react'
import './StudienDetails.css'
import './StudienList.js'
import './StudienList.js'
import { useParams, withRouter } from 'react-router'
import studienService from '../services/studien'
import PropTypes from 'prop-types'

/**
   Zeigt die Details einer Studie an, für die man sich anmelden kann.
   @component
   @example
   let studie = {
    studienname: "Studie XY",
    startdatum: new Date("2022-01-01"),
    enddatum: new Date("2021-12-31"),
    studienstunden: 2.5,
    kriterien: "Zu erfüllendes Kriteruim 1, zu erfüllendes Kriterium 2, ...",
    details: "Details der Studie"
  }
  return (<StudienDetails studie={studie}/>)
*/
const StudienDetails = (props) => {
  const [studie, setStudie] = useState(props.studie || props.location.state)
  const id = useParams().id
  useEffect(() => {
    const fetchData = async () => {
      setStudie(await studienService.getDetail(id))
    }
    if (studie === undefined) { fetchData() }
  }, [])
  //Formatiert das Datum in das deutsche Format tt/MM/JJJJ
  const formatter = new Intl.DateTimeFormat('de', { year: 'numeric', month: '2-digit', day: 'numeric' })
  return studie === undefined ? <div></div> : (
    <div>
      <h1> Studie: {studie.studienname} </h1>

      <div className="wrapper">
        <div className="left-part">
          <div className="header">Allgemeine Informationen:</div>
          <div className="grid-item">Studienleiter: {studie.studienleitername} </div>
          <div className="grid-item">Email: {studie.studienleiteremail}</div>
          <div className="grid-item">Betreuer: {studie.betreuername} </div>
          <div className="grid-item">Email: {studie.betreuer}</div>
          <div className="grid-item">Stunden: {studie.studienstunden}</div>
          <div className="grid-item">Startdatum: {formatter.format(new Date(Date.parse(studie.startdatum)))}</div>
          <div className="grid-item">Enddatum: {formatter.format(new Date(Date.parse(studie.enddatum)))}</div>
          <div className="grid-item">Status: <span className={studienService.status(studie)}/></div>
        </div>
        <div className="right-part">
          <div className="header">Kriterien:</div>
          <div className="grid-item">{studie.kriterien}</div>
        </div>
        <div className="lower-part">
          <div className="header">Beschreibung:</div>
          <div className="grid-item"> {studie.details}</div>
        </div>
      </div>
    </div>
  )
}

StudienDetails.propTypes = {
  studie: PropTypes.shape({
    studienleitername: PropTypes.string,
    studienleiteremail: PropTypes.string,
    studienstunden: PropTypes.number,
    startdatum: PropTypes.string,
    enddatum: PropTypes.string,
    kriterien: PropTypes.string,
    details: PropTypes.string,
  }),
}

export default withRouter(StudienDetails)
