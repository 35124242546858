import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import studienService from '../services/studien'
import './StudienList.css'
import './StudieForm.css'


const padding = {
  padding: 5,
}

/**
  Erzeugt eine Liste aller Studien, die genehmigt wurden und für die man sich anmelden kann
  oder, wenn params.user gegeben ist, eine Liste der Studien des User
  @component
*/


const StudienList = ({ studien, title, fetcher, detailPath }) => {
  const [studienList, setStudienList] = useState([])

  useEffect(() => {
    if (studien !== undefined) setStudienList(studien)
    else {
      const fetchData = async () => {
        const result = await fetcher()
        setStudienList(result)
      }
      fetchData()
    }
  }, [studien])
  /*
  Note: It is essential!!!! to add studien to the [] list in useEffect.
  Otherwise, if the component is re-rendered with a changed studien props,
  the effect won't happen.

  I also tried initializing useState to studien ?? [], and it did NOT
  reset it when the props changed.

  An alternate approach that DOES work is to compute studienToRender as either
  studien or studienList. That's perhaps cleaner.

  It was noticeable here because StudienList is called from MeineStunden,
  but the same problem is likely to occur for all components that can get
  the display data either through a prop or a state set in an effect
  */
  return (
    <div>
      <h1>{title}</h1>
      {
        studienList
          .sort((objA, objB) => {
            const diff1 = Number(new Date(objA.startdatum)) - Number(new Date(objB.startdatum))
            return diff1 !== 0 ? diff1 : Number(new Date(objA.enddatum)) - Number(new Date(objB.enddatum))
          })
          .map((studie) => {
            return (
              <Link
                className='studieInList'
                style={padding}
                to={{ pathname: `/${detailPath}/${studie._id}`, state: studie }}
                key={studie._id}
              >
                <li className='border' key={studie._id}>
                  {' '}
                  <div className='studienname'>{studie.studienname}</div>
                  Ausgeschrieben von: {studie.studienleitername} <br />
                  Stunden: {studie.studienstunden} <span className={studienService.status(studie)}/><br />
                </li>
              </Link>
            )
          }
          )
      }
    </div>
  )
}

export default StudienList
