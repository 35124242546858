import React, { useState, useEffect } from 'react'
import { useParams, withRouter, useHistory } from 'react-router-dom'
import Popup from 'reactjs-popup'
import studienService from '../services/studien'
import PropTypes from 'prop-types'
import StudienDetails from './StudienDetails'

/**
  Zeigt Details von einer eingereichten Studie, die noch bestätigt werden muss.
  @component
*/

const AntragDetails = (props) => {

  //Findet die ID eines bestimmten Antrags.
  const studienID = useParams().id

  const [studie, setStudie] = useState(props.studie)
  const history = useHistory() // TODO If switching to react-router-dom 6, change to useNavigate
  useEffect(() => {
    const fetchData = async () => {
      const studie = await studienService.getDetail(studienID)
      setStudie(studie)
    }
    if (studie === undefined) { fetchData() }
  }, [])

  /*
  //Lässt Elemente per Knopfdruck anzeigen/verstecken.
  function toggleElementDisplay(id) {
    var element = document.getElementById(id)
    var css = element.style
    if (css.display === 'block') {
      css.display = 'none'
      return
    }
    css.display = 'block'
  }
  */

  //Formatiert das Datum in das deutsche Format tt/MM/JJJJ
  const [begruendung, setBegruendung] = useState('')

  //Sendet eine Mail an den Studienleiter, wenn der Betreuer auf den Button "Studie annehmen" klickt, dass seine Studie genehmigt wurde.
  const studieanmelden = async () => {
    await studienService.studieAnnehmen(studienID)
    history.push('/')
  }


  //Sendet eine Mail an den Studienleiter, nachdem der Betreuer auf den Button "Studie ablehnen" klickt und eine Begrndung weshalb diese abgelehnt wurde schreibt.
  const studieablehnen = async () => {
    await studienService.studieAblehnen(studienID)
    history.push('/')
  }


  //Error Handler falls die Details der Studie nicht (schnell genug) laden.
  if (studie === undefined) {
    return (
      <div></div>
    )
  }

  return (
    <div>
      <StudienDetails studie={studie} />

      {props.user.betreuer === true ?
        <div>
          <Popup
            trigger={<label><span> <input className="button" type="button" value="Studie annehmen" onClick={() => studieanmelden()} /></span></label>}
            modal
            nested
          >
            {close => (
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <div className="header"> Studie genehmigt </div>
                <div className="content">
                  {' '}
                  Sie haben die Studie erfolgreich genehmigt.
                </div>
                <div className="actions">

                  <button
                    className="button"
                    onClick={() => {
                      console.log('modal closed ')
                      close()
                    }}
                  >
                    Schließen
                  </button>
                </div>
              </div>
            )}
          </Popup>

          <Popup
            trigger={<label><span> <input className="button" type="button" value="Studie ablehnen" /></span></label>}
            modal
            nested
          >
            {close => (
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <div className="header"> Studie ablehnen </div>
                <div className="content">
                  {' '}
                  Sie sind dabei, eine Studie abzulehnen.
                  <br />
                  Bitte schreiben Sie zunächst eine Begründung, weswegen Sie die Studie ablehnen und was an der Studie verändert werden muss,
                  damit Sie genehmigt wird.
                  <br />
                  <textarea type="text" className="textarea-field" value={begruendung} onChange={event => setBegruendung(event.target.value) }></textarea>

                </div>
                <div className="actions">


                  <Popup
                    trigger={<label><span> <input className="button" type="button" value="Studie ablehnen" onClick={() => studieablehnen()} /></span></label>}
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                        <div className="header"> Studie abgelehnt </div>
                        <div className="content">
                          {' '}
                          Sie haben die Studie erfolgreich abgelehnt.

                        </div>
                      </div>

                    )}
                  </Popup>


                  <button
                    className="button"
                    onClick={() => {
                      console.log('modal closed ')
                      close()
                    }}
                  >
                    Schließen
                  </button>
                </div>
              </div>
            )}
          </Popup></div> :
        <p>Die Studie wurde noch nicht vom Betreuer angenommen.</p>
      }
    </div>
  )
}
AntragDetails.propTypes = {
  studie: PropTypes.shape({
    studienleitername: PropTypes.string,
    studienleiteremail: PropTypes.string,
    studienstunden: PropTypes.number,
    startdatum: PropTypes.string,
    enddatum: PropTypes.string,
    kriterien: PropTypes.string,
    details: PropTypes.string,
  }),
  user: PropTypes.shape({
    betreuer: PropTypes.bool
  })
}
export default withRouter(AntragDetails)