/**
 * REST Requests zum Erhalten und Aktualisieren von Studien
 * @module services/studien
 */

import axios from 'axios'
const inProduction = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
const serverHost = inProduction ? '' : 'http://localhost:3001'

/**
 * GET Request, um alle verfügbaren Studien für die sich ein Sammler (Student) anmelden kann zu bekommen.
 * @returns {Promise<Studie[]>
 * } alle Studien, die angenommen wurden und zur Anmeldung zur Verfügung stehen
 */
const verfuegbar = async () => {
  const url = serverHost + '/api/studien/verfuegbar'
  const response = await axios.get(url)
  return response.data
}

/**
 * GET Request, um die Details von einer Studien einsehen zu können.
 * @param {String} studienID die ID der Studie
 * @returns {Promise<Studie>} die Details der Studie
 */
const getDetail = async (studienID) => {
  const url = serverHost + '/api/studien/'
  const response = await axios.get(`${url}/${studienID}`)
  return response.data
}

/**
 * GET Request, um alle Studien an denen ein Sammler teilgenommen hat zu bekommen.
 * @param {String} userName die E-Mail-Adresse des Users
 * @returns {Promise<Studie[]>} alle Studien, an den ein User teilnimmt/teilnehmen wird/teilgenommen
 */
const getSammlerTeilgenommeneStudien = async (userName) => {
  const url = serverHost + '/api/studien/sammler/teilgenommen'
  const response = await axios.get(`${url}/${userName}`)
  return response.data
}

/**
 * GET Request, um alle Studien zu erhalten, die neu erstellt wurden und noch vom
 * gegebenen Betreuer (Professor) akzeptiert werden müssen.
 * @param {String} userName die E-Mail-Adresse des Betreuers
 * @returns {Promise<Studie[]>} alle Studien, die vom Betreuer genehmigt werden müssen
 */
const getBetreuerAntraege = async (userName) => {
  const url = serverHost + '/api/studien/betreuer/offen'
  const response = await axios.get(`${url}/${userName}`)
  return response.data
}

/**
 * GET Request, um alle Studien zu erhalten, die neu erstellt wurden und noch vom
 * Betreuer (Professor) akzeptiert werden müssen.
 * @param {String} userName die E-Mail-Adresse des Studienleiters
 * @returns {Promise<Studie[]>} alle Studien, die vom Betreuer genehmigt werden müssen
 */
const getStudienleiterAntraege = async (userName) => {
  const url = serverHost + '/api/studien/studienleiter/offen'
  const response = await axios.get(`${url}/${userName}`)
  return response.data
}

/**
 * GET Request, um alle Studien, die ein Versuchsleiter erfolgreich erstellt hat auszugeben.
 * @param {String} userName eine PFH ID
 * @returns {Promise<Studie[]>} alle Studien des Studienleiters mit der gegebenen PFH ID
 */
const getStudienleiterStudien = async (userName) => {
  const url = serverHost + '/api/studien/studienleiter/all'
  const response = await axios.get(`${url}/${userName}`)
  return response.data
}

/**
 * GET Request, um alle Studien, für die der angemeldete Betreuer zuständig ist, anzuzeigen
 * @param {String} userName die PFH ID des Betreuers
 * @returns {Promise<Studie[]>} alle Studien, für die der Betreuer zuständig ist
 */
const getBetreuerStudien = async (userName) => {
  const url = serverHost + '/api/studien/betreuer/all'
  const response = await axios.get(`${url}/${userName}`)
  return response.data
}

/**
 * PATCH Request, mit der ein Betreuer eine Studie annehmen kann
 * @param {String} studienID die ID der Studie
 */
const studieAnnehmen = async (studienID) => {
  const url = serverHost + '/api/studien/betreuer/annehmen'
  await axios.patch(`${url}/${studienID}`, )
}

/**
 * PATCH Request, mit der ein Betreuer eine Studie ablehnen kann
 * @param {String} studienID die ID der Studie
 */
const studieAblehnen = async (studienID) => {
  const url = serverHost + '/api/studien/betreuer/ablehnen'
  await axios.patch(`${url}/${studienID}`, )
}

/**
 * POST Request, um eine Studie einzureichen
 * @param {String} studienleiteremail E-Mail-Adresse des Studienleiters
 * @param {String} studienleitername Name des Studienleiters
 * @param {String} betreuer E-Mail-Adresse des Betreuers
 * @param {String} studienname Name der Studie
 * @param {String} startdatum Startdatum der Studie
 * @param {String} enddatum Enddatum der Studie
 * @param {Number} studienstunden Stunden der Studie
 * @param {String} details Details der Studie
 * @param {String} kriterien Kriterien der Studie
 */
const studieEinreichen = async (studie) => {
  const url = serverHost + '/api/studien'
  await axios.post(url, studie)
}

/**
 * POST Request, um eine Studie zu aktualisieren
 * @param {studie} studie Studie, die neue/geänderte Daten enthält
 */

const studieAktualisieren = async (studie) => {
  const url = serverHost + '/api/studien/aktualisieren'
  await axios.post(url, studie)
}

/**
 * DELETE Request, um eine Studie zu löschen
 * @param {String} id ID der Studie
 */
const deleteStudie = async (id) => {
  const url = serverHost + '/api/studien/betreuer'
  await axios.delete(`${url}/${id}`)

}
/**
 * Post die ID von Sammlern überprüft und hinzufügt, damit wird Teilnahme an Studie bestätigt.
 * @param {string} id ID der Studie
 * @param {string[]} genehmigteSammler VPS IDs der Sammler
 */
const sammlerBestaetigen = async (id, genehmigteSammler) => {
  const url = serverHost + '/api/studien/sammler/bestaetigen'
  const result = await axios.post(url, { id, genehmigteSammler })
  return result.data
}

/**
 * Request, mit der das Prüfungsamt über die geleisteten Studen des Sammlers informiert wird
 * @param {string} sammleremail Die E-Mail-Adresse des Users
 * @param {boolean} fern true für Fernstudenten, false für Präsenzstudenten
 */
const sendSammlerStundenbericht = async (sammleremail, fern) => {
  const url = serverHost + '/api/studien/sammler/stundenbericht'
  await axios.post(url, { sammleremail, fern })
}

/**
 * GET Request um die aufsummierten Stunden, die ein User bereits gesammelt hat zu bekommen.
 * @param {String} userName die E-Mail-Adresse des Users
 * @returns {Array} die aufsummierten gesammelten Stunden des Users
 */
const getSammlerStunden = async (userName) => {
  const url = serverHost + '/api/studien/sammler/stunden'
  const response = await axios.get(`${url}/${userName}`)
  return response.data
}

const status = (studie) => {
  const now = new Date().toISOString()
  if (studie.geloescht) return 'geloescht'
  else if (studie.studieoffen) return 'offen'
  else if (!studie.studieangenommen) return 'abgelehnt'
  else if (now < studie.startdatum) return 'voranfang'
  else if (now > studie.enddatum) return 'nachende'
  else return 'laufend'
}

export default {
  verfuegbar,
  getDetail,
  getStudienleiterAntraege,
  getStudienleiterStudien,
  getBetreuerAntraege,
  getBetreuerStudien,
  studieAnnehmen,
  studieAblehnen,
  studieEinreichen,
  studieAktualisieren,
  deleteStudie,

  getSammlerStunden,
  getSammlerTeilgenommeneStudien,
  sammlerBestaetigen,
  sendSammlerStundenbericht,

  status
}