/* eslint-disable linebreak-style */
import React, { useState } from 'react'
import UserService from '../services/user'
import './StudieForm.css'
/**
  - Nur User, die Sammler und Studienleiter sind, können diesen Bereich sehen.
  - Zeigt die Informationen "Name", "E-Mail" und "Versuchspersonen ID" über einen Nutzer an.

   @component
   @example
   <Nutzer user={{ name: 'Fred Flintstone', email: 'fred.flintstone@pfh.de', vpsid: 'fröhlicher-hamster1773'}}/>

*/

const Nutzer = ({ user }) => {

  // für aktualisieren der VPSID in der Anzeige
  function handleChange(evt) {
    const value = evt.target.value
    setState({
      ...state,
      [evt.target.name]: value
    })
  }
  //setzt VPSID auf die aktuelle VPSID
  const [state, setState] = useState({
    vpsid: user.vpsid,
  })
  // Get Befehl um neue ID von Datenbank zu erhalten
  const getNeueVpsid = async () => {
    const requestBody = { ...state, _id: getNeueVpsid }
    const result = await UserService.getNeueVpsid(requestBody)
    setState({ vpsid: result.data.toString().replace(/,/g,'\n') })
    console.log('Neue VPSID', result)
  }
  console.log('Nutzer', user)
  // Button "ändern Versuchspersonen ID" deaktivieren und grau färben
  const [disable, setDisable] = useState(false)
  return (
    <div className="nutzer">
      <table>
        <tr>
          <td>Name</td>
          <td>{user.name}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{user.email}</td>
        </tr>
        <tr>
          <td>Versuchspersonen ID</td>
          <td onChange={handleChange}>{state.vpsid}</td>
        </tr>
      </table>
      <p>Die Versuchspersonen ID ist Ihre persönliche anonyme Kennung für die Teilnahme an Studien. Geben Sie die Versuchspersonen ID an, wenn Sie bei der Teilnahme an Studien danach gefragt werden. Die Teilnahme wird Ihnen dann nach Beendigung der jeweiligen Studie gutgeschrieben.</p>
      <p>Sie können Ihre Versuchspersonen ID ändern, wenn sie nicht mehr anonym ist oder wenn sie Ihnen nicht gefällt.</p>
      <p>Die Versuchspersonen ID ist automatisch und zufällig erzeugt.</p>
      {<label><span>  <button disabled={disable ? 'disabled' : ''} onClick={() => {setDisable(true); getNeueVpsid()}}>Versuchspersonen ID ändern </button>
      </span></label>}
    </div>
  )
}
export default Nutzer