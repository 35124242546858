import { createRoot } from 'react-dom/client'
import React from 'react'
import App from './App'
import './index.css'
import { BrowserRouter as Router } from 'react-router-dom'

const container = document.getElementById('root')
const root = createRoot(container)
let version= process.env.REACT_APP_GIT_VERSION_FRONTEND
version
root.render(
  <Router>
    <App />
  </Router>
)
